import { navigate } from 'gatsby';
import React from 'react';

import { useAuth } from 'context/UserContext';
import LoginPage from 'components/account/login/LoginPage';
import FormattedMessage from 'components/utils/FormattedMessage';

const Login = () => {
    const { isSignedIn } = useAuth();
    if (isSignedIn) navigate('/account');

    return (
        <div className="wrapper">
            {isSignedIn && (
                <p><FormattedMessage id="login.redirecting"/></p>
            )}

            {!isSignedIn && (
                <LoginPage />
            )}
        </div>
    );
};

export default Login;
