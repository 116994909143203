import React, { useContext } from 'react';
import ImageGatsby from 'gatsby-plugin-sanity-image';

import * as style from 'styles/components/account/loginPage.module.scss';
import { LangContext } from 'context/LangContext';
import { useAuth } from 'context/UserContext';
import useLoginPage from 'data/queries/useLoginPage';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';
import TextEditorRender from 'components/utils/TextEditorRender';

const LoginPage = () => {
    const { lang } = useContext(LangContext);
    const { signIn } = useAuth();
    const loginPage = useLoginPage();

    const handlePageSkipCheckbox = (evt) => {
        if (typeof localStorage === 'undefined') return;

        localStorage.setItem('skipLoginPage', JSON.stringify(evt.target.checked));
    };

    return (
        <div className={style.container}>
            <div className={style.content}>
                <h1 className="title-md-mobile title-xl bold">{loginPage.loginTitle[lang.translationKey]}</h1>

                {loginPage.loginContent[lang.translationKey] && (
                    <TextEditorRender content={loginPage.loginContent[lang.translationKey]._rawContent} />
                )}

                <button type="button" onClick={signIn} className="text-center cta-yellow" aria-label={getFormattedMessage('login.login', lang)}>
                    <FormattedMessage id="login.login" />
                </button>

                <div className={`${style.checkbox} form-group checkbox`}>
                    <input type="checkbox" name="skipLoginPage" id="skipLoginPage" onChange={handlePageSkipCheckbox} />
                    <label htmlFor="skipLoginPage">
                        <FormattedMessage id="login.skipPage" />
                    </label>
                </div>

                {loginPage.loginLogos.length !== 0 && (
                    <ul className={style.logos}>
                        {loginPage.loginLogos.map(logo => (
                            <li key={logo._key} className={style.logo}>
                                <a href={logo.url} target="_blank" rel="noreferrer">
                                    <ImageGatsby
                                        {...logo.image}
                                        alt={logo.alt || ''}
                                    />
                                </a>
                            </li>
                        ))}
                    </ul>
                )}
            </div>

            {loginPage.loginImage && loginPage.loginImage.image && (
                <div className={style.aside}>
                    <ImageGatsby
                        {...loginPage.loginImage.image}
                        alt={loginPage.loginImage.alt || ''}
                    />
                </div>
            )}
        </div>
    );
};

export default LoginPage;
