import { useStaticQuery, graphql } from 'gatsby';

const useLoginPage = () => {
    const {
        sanityConfigAccount: loginPage,
    } = useStaticQuery(graphql`
        query {
            sanityConfigAccount {
                loginTitle {
                    en
                    se
                }
                loginContent {
                    en {
                        _rawContent
                    }
                    se {
                        _rawContent
                    }
                }
                loginImage {
                    alt
                    image {
                        ...ImageWithPreview
                    }
                }
                loginLogos {
                    _key
                    url
                    alt
                    image {
                        ...ImageWithPreview
                    }
                }
            }
        }
    `);

    return loginPage;
};

export default useLoginPage;
